import { Configuration, FrontendApi, Session } from "@ory/kratos-client";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import React, { useCallback, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import metrics from "../metrics/metrics";
import { CurrentUser, UserContext } from "../types/user";
import { FeatureFlagsContext } from "./feature-flags";
import { currentUser } from "../api_generated";
import { User } from "@auth0/auth0-react";

export function getKratosEndpoint() {
    const hostname = window.location.hostname.toString();
    const protocol = "https://";
    if (hostname.startsWith("localhost")) {
        if (process.env.REACT_APP_PROXY_API === "true") {
            return "/kratos";
        }
        return "http://localhost:8081/kratos";
    }
    return `${protocol}auth.${hostname}`;
}

const config = {
    kratos: {
        public: getKratosEndpoint(),
    },
};

export const kratosSdk = new FrontendApi(
    new Configuration({
        basePath: config.kratos.public,
        // we always want to include the cookies in each request
        // cookies are used for sessions and CSRF protection
        baseOptions: {
            withCredentials: true,
        },
    }),
);

export const isKratosSession = (session: Session | User) => {
    return "identity" in session;
}

export const isKratosUserContext = (userCtx: UserContext | CurrentUser | currentUser) => {
    return "id" in userCtx;
};

/**
 * @param getFlow - Should be function to load a flow make it visible (Login.getFlow)
 * @param setFlow - Update flow data to view (Login.setFlow)
 * @param defaultNav - Default navigate target for errors
 * @param fatalToDash - When true and error can not be handled, then redirect to dashboard, else rethrow error
 */
export const useSDKError = (
    getFlow: ((flowId: string) => Promise<void | AxiosError>) | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFlow: React.Dispatch<React.SetStateAction<any>> | undefined,
    defaultNav: string | undefined,
    fatalToDash = false,
) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const { featureFlags } = useContext(FeatureFlagsContext);

    return useCallback(
        (error: AxiosError): Promise<AxiosError | void> => {
            const responseData = error.response?.data || ({} as any);

            switch (error.response?.status) {
                case 500: {
                    // This is an internal server issue, return the error so we can render it to the user:
                    metrics.capture("kratos_error", {
                        data: responseData,
                        status: error.response?.status,
                        error: error.response?.statusText,
                        url: window.location.href,
                    });
                    return Promise.reject(error);
                }
                case 400: {
                    if (responseData?.error?.id === "session_already_available") {
                        console.warn("sdkError 400: `session_already_available`. Navigate to /");
                        Sentry.captureMessage("Session already available");

                        // Typically the user is redirected to the Sign In page. Insure they go back where they came from.
                        const returnTo = params.get("return_to") || "/";
                        navigate(returnTo, { replace: true });
                        return Promise.resolve();
                    }
                    // the request could contain invalid parameters which would set error messages in the flow
                    if (setFlow !== undefined) {
                        console.warn("sdkError 400: update flow data");
                        setFlow(responseData);
                        return Promise.resolve();
                    }
                    break;
                }
                case 401: {
                    // We need these pages to be accessible without a valid session so the user can create a valid session:
                    const publicPages = ["/auth/signup", "/recovery", "/auth/error"];
                    if (publicPages.includes(window.location.pathname)) {
                        return Promise.resolve();
                    }

                    console.warn("sdkError 401: Navigate to login");
                    // TODO: Custom hack needed to get past login screen after auth0 error. Have to use a local feature
                    // flag instead of a posthog one because the latter is undefined on component mount.
                    if (featureFlags["Kratos Hack"]) {
                        navigate("/", { replace: true });
                    } else {
                        navigate("/auth/signin", { replace: true });
                    }
                    return Promise.resolve();
                }
                case 403: {
                    // the user might have a session, but would require 2FA (Two-Factor Authentication)
                    if (responseData.error?.id === "session_aal2_required") {
                        navigate("/auth/signin?aal2=true", { replace: true });
                        return Promise.resolve();
                    }

                    if (responseData.error?.id === "session_refresh_required" && responseData.redirect_browser_to) {
                        console.warn("sdkError 403: Redirect browser to");
                        window.location = responseData.redirect_browser_to;
                        return Promise.resolve();
                    }
                    break;
                }
                case 404: {
                    if (defaultNav !== undefined) {
                        console.warn("sdkError 404: Navigate to Error");
                        const errorMsg = {
                            data: error.response?.data || error,
                            status: error.response?.status,
                            statusText: error.response?.statusText,
                            url: window.location.href,
                        };

                        navigate(`/auth/error?error=${encodeURIComponent(JSON.stringify(errorMsg))}`, {
                            replace: true,
                        });
                        return Promise.resolve();
                    }
                    break;
                }
                case 410: {
                    if (getFlow !== undefined && responseData.use_flow_id !== undefined) {
                        console.warn("sdkError 410: Update flow");
                        return getFlow(responseData.use_flow_id).catch((error) => {
                            // Something went seriously wrong - log and redirect to defaultNav if possible
                            console.error(error);

                            if (defaultNav !== undefined) {
                                navigate(defaultNav, { replace: true });
                            } else {
                                // Rethrow error when can't navigate and let caller handle
                                throw error;
                            }
                        });
                    } else if (defaultNav !== undefined) {
                        console.warn("sdkError 410: Navigate to", defaultNav);
                        navigate(defaultNav, { replace: true });
                        return Promise.resolve();
                    }
                    break;
                }
                case 422: {
                    if (responseData.redirect_browser_to !== undefined) {
                        const currentUrl = new URL(window.location.href);
                        const redirect = new URL(
                            responseData.redirect_browser_to,
                            // need to add the base url since the `redirect_browser_to` is a relative url with no hostname
                            window.location.origin,
                        );

                        // Path has changed
                        if (currentUrl.pathname !== redirect.pathname) {
                            console.warn("sdkError 422: Update path");
                            // remove /ui prefix from the path in case it is present (not setup correctly inside the project config)
                            // since this is an SPA we don't need to redirect to the Account Experience.
                            redirect.pathname = redirect.pathname.replace("/ui", "");
                            navigate(redirect.pathname + redirect.search, {
                                replace: true,
                            });
                            return Promise.resolve();
                        }

                        // for webauthn we need to reload the flow
                        const flowId = redirect.searchParams.get("flow");

                        if (flowId != null && getFlow !== undefined) {
                            // get new flow data based on the flow id in the redirect url
                            console.warn("sdkError 422: Update flow");
                            return getFlow(flowId).catch((error) => {
                                // Something went seriously wrong - log and redirect to defaultNav if possible
                                console.error(error);

                                if (defaultNav !== undefined) {
                                    navigate(defaultNav, { replace: true });
                                } else {
                                    // Rethrow error when can't navigate and let caller handle
                                    throw error;
                                }
                            });
                        } else {
                            console.warn("sdkError 422: Redirect browser to");
                            // https://www.ory.sh/docs/kratos/bring-your-own-ui/custom-ui-advanced-integration#spas-and-the-422-error
                            navigate(responseData.redirect_browser_to, { replace: true });
                            return Promise.resolve();
                        }
                    }
                }
            }

            console.error(error);

            if (fatalToDash) {
                console.warn("sdkError: fatal error redirect to dashboard");
                navigate("/", { replace: true });
                return Promise.resolve();
            }

            throw error;
        },
        [navigate, getFlow], // eslint-disable-line react-hooks/exhaustive-deps
    );
};
