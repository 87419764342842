import { TimePicker } from "antd";
import type { Dayjs } from "dayjs";
import { useState } from "react";
import { Accordion, Form, Header, Icon } from "semantic-ui-react";
import metrics from "../../../metrics/metrics";
import { SEMANTIC_GREY_DISABLED } from "../../../utils/colors";
import { convertAtomicToNumber } from "../../../utils/config";
import dayjsExtended from "../../../utils/dayjs";
import { useConfigState, useDispatch } from "../store";

const timeLimitFormat = "HH:mm";

const MetricRoot = "Model.Config.Hyperopt";
const InputGroupExecutor = ".Executor";

function ExecutorForm() {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { config, invalidFields } = useConfigState();
    const hyperoptConfig = config?.hyperopt;

    const onTimeChange = (timeLimit: Dayjs) => {
        console.log(timeLimit);
        if (timeLimit != null) {
            const timeDiff = timeLimit.diff(dayjsExtended().startOf("day"), "seconds");
            dispatch({ type: "UPDATE_CONFIG_PROPERTY", field: "hyperopt.executor.time_budget_s", value: timeDiff });
        } else {
            // Remove time_budget_s:
            dispatch({ type: "REMOVE_CONFIG_PROPERTY", field: "hyperopt.executor.time_budget_s" });
        }
    };

    const getTimeLimitMoment = () => {
        let timeLimitS = hyperoptConfig?.executor?.time_budget_s;
        if (!timeLimitS) {
            return null;
        }

        return dayjsExtended().startOf("day").second(timeLimitS);
    };

    return (
        <Accordion
            styled
            fluid
            style={{ boxShadow: "0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%)", margin: "1rem 0" }}
            defaultActiveIndex={-1}
        >
            <Accordion.Title
                active={open}
                onClick={() => setOpen((open) => !open)}
                style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch", padding: "1.75rem" }}
            >
                <Header className="header" as="h3" style={{ marginBottom: 0 }}>
                    Executor
                </Header>
                <Icon name={open ? "chevron up" : "chevron down"} />
            </Accordion.Title>
            <Accordion.Content active={open} style={{ padding: "1.75rem", paddingTop: 0 }}>
                <p style={{ marginBottom: "28px", color: SEMANTIC_GREY_DISABLED }}>
                    Predibase uses the Ray Tune executor which enables Ray Tune for distributed hyperopt across a
                    cluster of machines.{" "}
                    <a
                        href={
                            "https://ludwig-ai.github.io/ludwig-docs/0.5/configuration/hyperparameter_optimization/#ray-tune-executor"
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        {"Learn more."}
                    </a>
                </p>
                <Form>
                    <div className="field">
                        <label>{"Time Limit (HH:mm)"}</label>
                        <TimePicker
                            className={`metrics.BLOCK_AUTO_CAPTURE time-picker-component`}
                            onClick={() => metrics.captureClick(MetricRoot + InputGroupExecutor + ".TimeLimit")}
                            // @ts-ignore Equivalent API, see note in `package-json-notes.md`
                            onChange={onTimeChange}
                            // @ts-ignore Equivalent API, see note in `package-json-notes.md`
                            onCalendarChange={onTimeChange}
                            // @ts-ignore Equivalent API, see note in `package-json-notes.md`
                            value={getTimeLimitMoment()}
                            format={timeLimitFormat}
                            status={invalidFields["hyperopt/executor/time_budget_s"] ? "error" : ""}
                            showNow={false}
                            style={{ width: "197px", height: "38px" }}
                        />
                    </div>
                    <Form.Input
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        name="numSamples"
                        label="Number of Samples"
                        style={{ width: "197px" }}
                        placeholder="Num Samples"
                        value={hyperoptConfig?.executor?.num_samples}
                        error={invalidFields["hyperopt/executor/num_samples"]}
                        onClick={() => metrics.captureClick(MetricRoot + InputGroupExecutor + ".NumSamples")}
                        onChange={(event) => {
                            const newValue = event.target.value;
                            const convertedValue = convertAtomicToNumber(newValue);
                            dispatch({
                                type: "UPDATE_CONFIG_PROPERTY",
                                field: "hyperopt.executor.num_samples",
                                value: convertedValue,
                            });
                        }}
                    />
                </Form>
            </Accordion.Content>
        </Accordion>
    );
}

export default ExecutorForm;
