import { Popup } from "semantic-ui-react";

const TruncatedText = (props: { text: string }) => {
    const { text } = props;

    return (
        <Popup
            className="transition-scale"
            position="right center"
            trigger={
                <p style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", lineHeight: "1rem" }}>
                    {text}
                </p>
            }
            content={text}
        />
    );
};

export default TruncatedText;
