import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SESSION_STATE } from "../state/global";
import { kratosSdk, useSDKError } from "../utils/kratos";

export const useKratosAuth = (useKratos: boolean) => {
    // Local state:
    const [kratosLoading, setKratosLoading] = useState(true);
    const [validSession, setValidSession] = useState(false);

    // Global Auth state:
    const [, setSession] = useRecoilState(SESSION_STATE);

    const navigate = useNavigate();
    const sdkErrorHandler = useSDKError(undefined, undefined, "/auth/signin");

    // Get Kratos session:
    useEffect(() => {
        if (!useKratos) {
            return setKratosLoading(false);
        }

        // we check if the user is logged in by checking if there is a session
        // if no session is found, we redirect to the login page
        kratosSdk
            .toSession()
            .then(({ data: session }) => {
                // we set the session data which contains the user Identifier and other traits.
                setSession(session);
                setValidSession(true);
            })
            .catch(sdkErrorHandler)
            .catch((error) => {
                // Handle all other errors like error.message "network error" if Kratos can not be connected etc.
                if (error.message) {
                    return navigate(`/auth/error?error=${encodeURIComponent(error.message)}`, {
                        replace: true,
                    });
                }

                // Just stringify error and print all data
                navigate(`/auth/error?error=${encodeURIComponent(JSON.stringify(error))}`, {
                    replace: true,
                });
            })
            .finally(() => setKratosLoading(false));
    }, [navigate, sdkErrorHandler, setSession, useKratos]);

    return {
        authLoading: kratosLoading,
        validSession,
    };
};

export const useAuth0Auth = (useAuth0Auth: boolean) => {
    // Global Auth state:
    const [, setSession] = useRecoilState(SESSION_STATE);

    const { isLoading: auth0IsLoading, isAuthenticated: auth0IsAuthenticated, user: auth0User } = useAuth0();

    useEffect(() => {
        if (!useAuth0Auth) {
            return;
        }

        if (!auth0IsLoading && auth0IsAuthenticated) {
            setSession(auth0User);
        }
    }, [useAuth0Auth, auth0IsLoading, auth0User]);

    return {
        authLoading: auth0IsLoading,
        validSession: auth0IsAuthenticated,
    };
};
