import { Link } from "react-router-dom";
import { Breadcrumb, Icon } from "semantic-ui-react";
import { repo } from "../api_generated";
import metrics from "../metrics/metrics";

const Viewcrumb = (props: { adapterRepo: repo }) => {
    return (
        <Breadcrumb.Section>
            <Link to={"/adapters/repo/" + props.adapterRepo?.uuid} className={metrics.BLOCK_AUTO_CAPTURE}>
                <Icon name={"folder outline"} />
                {props.adapterRepo?.name}
            </Link>
        </Breadcrumb.Section>
    );
};

const Breadcrumbs = (props: { adapterRepo?: repo; adapterVersionText?: string }) => {
    return (
        <Breadcrumb>
            <Breadcrumb.Section>
                <Link to="/adapters" className={metrics.BLOCK_AUTO_CAPTURE}>
                    Adapters
                </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            {props.adapterRepo?.uuid && <Viewcrumb adapterRepo={props.adapterRepo} />}
            {props.adapterVersionText && (
                <>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active style={{ fontWeight: "normal" }}>
                        {props.adapterVersionText}
                    </Breadcrumb.Section>
                </>
            )}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
