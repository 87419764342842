/* eslint-disable react/jsx-no-target-blank */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Divider, Message, Modal } from "semantic-ui-react";
import { baseModel, completeAdapterUploadRequest, repo, tier } from "../../../api_generated";
import { DropdownField } from "../../../components/modal-utils";
import { useAuth0TokenOptions } from "../../../data";
import { useBaseModelsQuery } from "../../../deployments/data/query";
import { generateBaseModelSelectorOptions } from "../../../deployments/misc/dropdown-utils";
import { USER_STATE } from "../../../state/global";
import { SEMANTIC_BLACK, SEMANTIC_WHITE } from "../../../utils/colors";
import { isKratosUserContext } from "../../../utils/kratos";
import { beginAdapterVersionUpload, completeAdapterVersionUpload } from "../../data";
import { GET_ADAPTER_REPOS_QUERY_KEY } from "../../query";

// Extends React's HTMLAttributes since we want the user to select a directory.
// See https://github.com/facebook/react/issues/3468#issuecomment-1031366038.
declare module "react" {
    interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
        webkitdirectory?: string;
    }
}

type uploadToPresignedUrlParams = {
    presignedUrl: string;
    file: File;
    requiredHeaders: Record<string, string>;
};

const UploadAdapterVersionModal = (props: {
    repo: repo;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    // Parent state:
    const { repo, open, setOpen } = props;

    // Recoil state:
    const [userContext] = useRecoilState(USER_STATE);
    // Derived user state:
    let userTier: tier | undefined;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        userTier = isKratosContext ? userContext.tenant.subscription.tier : userContext?.tenant.tier;
    }

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [baseModelOption, setBaseModelOption] = useState<string>("");
    const [files, setFiles] = useState<(File | null)[]>();
    const [dirName, setDirName] = useState<string>("");
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

    // Query state:
    const queryClient = useQueryClient();
    const { data: baseModels } = useBaseModelsQuery({
        refetchOnWindowFocus: false,
    });

    const { baseModelSelectorOptions, baseModelLookup } = useMemo(() => {
        const [baseModelSelectorOptions, baseModelLookup] = generateBaseModelSelectorOptions(baseModels, userTier);
        return { baseModelSelectorOptions, baseModelLookup };
    }, [baseModels, userTier]);

    // Auto-select the first base model option after option generation:
    useEffect(() => {
        if (baseModelSelectorOptions.length > 0 && baseModelOption === "") {
            setBaseModelOption(baseModelSelectorOptions[0].value as baseModel["name"]);
        }
    }, [baseModelSelectorOptions]);

    const { mutateAsync: beginUpload, reset: resetBeginUploadMutation } = useMutation({
        // TODO: make this the shortname instead (follow-up PR since it requires changes elsewhere as well).
        mutationFn: () =>
            beginAdapterVersionUpload(
                {
                    repo: repo.uuid,
                    baseModel: baseModelLookup[baseModelOption].name,
                },
                auth0TokenOptions,
            ),
    });

    const { mutateAsync: completeUpload, reset: resetCompleteUploadMutation } = useMutation({
        mutationFn: (req: completeAdapterUploadRequest) => completeAdapterVersionUpload(req, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_ADAPTER_REPOS_QUERY_KEY() });
            setOpen(false);
            resetMutations();
        },
    });

    const { mutateAsync: uploadToPresignedURL, reset: resetUploadToPresignedURLMutation } = useMutation({
        mutationFn: async (params: uploadToPresignedUrlParams) => {
            const resp = await fetch(params.presignedUrl, {
                method: "PUT",
                body: params.file,
                headers: {
                    "Content-Type": "application/octet-stream",
                    ...params.requiredHeaders,
                },
            });

            if (!resp.ok) {
                throw new Error(`Failed to upload ${params.file.name} - ${resp.status}: ${resp.statusText}`);
            }
        },
    });

    const onDirChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        setErrorMessage("");
        setFiles(undefined);
        setDirName("");

        if (!e.target.files) {
            return;
        }

        let configFile: File | null = null;
        let safetensorsFile: File | null = null;
        let binFile: File | null = null;
        for (const f of e.target.files) {
            switch (f.name) {
                case "adapter_config.json":
                    configFile = f;
                    break;
                case "adapter_model.safetensors":
                    safetensorsFile = f;
                    break;
                case "adapter_model.bin":
                    binFile = f;
                    break;
            }
        }

        if (!configFile) {
            setErrorMessage("Selected directory must contain an adapter_config.json file.");
            return;
        }

        if (!safetensorsFile && !binFile) {
            setErrorMessage(
                "Selected directory must contain either an adapter_model.safetensors or an adapter_model.bin file.",
            );
            return;
        }

        setFiles([configFile, safetensorsFile, binFile]);
        setDirName(e.target.files[0].webkitRelativePath.split("/")[0] + "/");
    };

    const doUpload = async () => {
        if (!files) {
            throw new Error("List of files to upload is empty or undefined.");
        }

        const beginUploadResp = await beginUpload();

        for (const file of files) {
            if (!file) {
                continue;
            }

            if (!(file.name in beginUploadResp.presignedUrls)) {
                throw new Error(`No upload URL found for ${file.name}`);
            }

            await uploadToPresignedURL({
                presignedUrl: beginUploadResp.presignedUrls[file.name],
                file: file,
                requiredHeaders: beginUploadResp.requiredHeaders,
            });
        }

        await completeUpload({
            uploadToken: beginUploadResp.uploadToken,
        });
    };

    const resetMutations = () => {
        resetBeginUploadMutation();
        resetCompleteUploadMutation();
        resetUploadToPresignedURLMutation();
    };

    return (
        <Modal
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            open={open}
        >
            <Modal.Header>Upload an Adapter</Modal.Header>
            <Modal.Content>
                <span>
                    Import an adapter trained outside of Predibase for inference using a Predibase deployment. To be
                    compatible with Predibase, the top level of your adapter directory must include the following:
                    <ul>
                        <li>
                            an <strong>adapter_config.json</strong> file
                        </li>
                        <li>
                            at least one of <strong>adapter_model.safetensors</strong> or{" "}
                            <strong>adapter_model.bin</strong>
                        </li>
                    </ul>
                    Only these required files will be uploaded. All other files in the directory are ignored. See{" "}
                    <a href="https://loraexchange.ai/models/adapters/" target="_blank" rel="noopener">
                        this documentation
                    </a>{" "}
                    to learn more about adapters and how they work.
                </span>

                <h2 className="ui small header" style={{ marginBottom: "0.357143rem" }}>
                    Adapter files
                </h2>
                <Button
                    content="Select directory"
                    as="label"
                    htmlFor="file"
                    type="button"
                    labelPosition="left"
                    icon="folder"
                    style={{ color: SEMANTIC_WHITE, backgroundColor: SEMANTIC_BLACK }}
                />
                <input type="file" id="file" webkitdirectory="" hidden onChange={onDirChange} />
                <label>
                    {dirName ? (
                        <>
                            &nbsp;&nbsp;&nbsp;Required files found in <strong>{dirName}</strong>&nbsp;&nbsp;&#x2705;
                        </>
                    ) : (
                        ""
                    )}
                </label>

                <Divider hidden />

                <DropdownField
                    name="baseModel"
                    placeholder="Model"
                    value={baseModelOption}
                    setValue={setBaseModelOption}
                    header="Base model"
                    description="Note: if you fine-tuned on a custom base model and don't see it here, you can provide a custom model name by uploading via the SDK."
                    options={baseModelSelectorOptions}
                />

                {errorMessage ? <Message negative>{errorMessage}</Message> : null}
            </Modal.Content>

            {/* // Bottom bar with buttons: */}
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        resetMutations();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    className={uploadInProgress ? "loading" : ""}
                    color={"green"}
                    onClick={() => {
                        setUploadInProgress(true);
                        doUpload()
                            .catch((error) => {
                                setErrorMessage(error.toString());
                            })
                            .finally(() => {
                                setUploadInProgress(false);
                            });
                    }}
                    disabled={!files}
                    loading={uploadInProgress}
                >
                    Upload
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default UploadAdapterVersionModal;
