import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, Navigate, useMatch } from "react-router-dom";
import { Breadcrumb, Button, Divider, Header, Icon, Image, Modal, Tab } from "semantic-ui-react";
import { BadLink } from "../../components/BadLink/BadLink";
import ErrorMessage from "../../components/ErrorMessage";
import InfoMessage from "../../components/InfoMessage";
import CountUpTimer from "../../components/unsorted/history/CountUpTimer";
import { useAuth0TokenOptions } from "../../data";
import { SEMANTIC_BLUE } from "../../utils/colors";
import { getImageForConnector } from "../../utils/connectors";
import dayjsExtended from "../../utils/dayjs";
import { getErrorMessage } from "../../utils/errors";
import { deleteDataset } from "../data";
import { GET_DATASET_MODELS_QUERY_KEY, useDatasetModelsQuery } from "../query";
import AugmentDatasetModal from "./AugmentDatasetModal";
import DatasetDetailsTable from "./DatasetDetailsTable";
import DatasetPreviewer from "./DatasetPreviewer";

const DatasetDetailsView = () => {
    const auth0TokenOptions = useAuth0TokenOptions();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [dataset, setDataset] = useState<Dataset>();
    const [schemas, setSchemas] = useState<DataField[]>();
    const [deleted, setDeleted] = useState(false);
    const [augmentDatasetModalOpen, setAugmentDatasetModalOpen] = useState(false);

    const match = useMatch("/data/datasets/:id");
    // TODO: This is unsafe:
    const datasetID = parseInt(match!.params.id!);

    const queryClient = useQueryClient();
    // TODO: Again, this terminology (from our gateway) makes no sense. What are "dataset models"?
    const { data: datasetModelsSchemaWhatever, error: idkError } = useDatasetModelsQuery(datasetID, {
        refetchInterval: 5 * 1000, // 5 seconds
    });
    useEffect(() => {
        if (datasetModelsSchemaWhatever) {
            const resDataset = datasetModelsSchemaWhatever.dataset;
            setDataset(resDataset);
            if (resDataset.status === "connecting" || resDataset.status === "refreshing") {
                return;
            }
            if (resDataset.status === "errored") {
                setErrorMessage(resDataset.lastError);
            } else {
                const fields = resDataset.fields;
                setSchemas(fields);
            }
            const fields = datasetModelsSchemaWhatever.dataset.fields;
            setSchemas(fields);
        }
    }, [datasetModelsSchemaWhatever]);
    useEffect(() => {
        if (idkError) {
            setErrorMessage(getErrorMessage(idkError));
        }
    }, [idkError]);

    const { mutate: mutateDeleteDataset } = useMutation({
        mutationFn: () => deleteDataset(dataset!.id, auth0TokenOptions),
        onSuccess: () => {
            setDeleted(true);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    if (deleted) {
        return <Navigate to={"/data"} />;
    }

    let errorView = null;

    if (errorMessage) {
        errorView = <ErrorMessage header={"Error connecting dataset"} errorMessage={errorMessage} />;
    }

    if (errorMessage && !dataset) {
        return <BadLink authenticated={true} />;
    }

    let connectionMsg = null;
    if (dataset && dataset.status === "connecting") {
        const created = dayjsExtended(new Date(dataset.created));
        const createdString = created.format("YYYY-MM-DD HH:mm:ss");
        connectionMsg = (
            <InfoMessage
                header={`Dataset is still connecting...`}
                infoMessage={
                    <>
                        <br />
                        <div>
                            <b>Started</b> &nbsp;&emsp;{createdString}
                            <br />
                            <b>Elapsed</b> &emsp;
                            <CountUpTimer color={SEMANTIC_BLUE} start={created} />
                        </div>
                    </>
                }
            />
        );
    }

    let header = null;
    let breadcrumb = (
        <Breadcrumb>
            <Breadcrumb.Section>
                <Link to="/data">Data</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>Dataset Details</Breadcrumb.Section>
        </Breadcrumb>
    );

    if (dataset) {
        header = (
            <div>
                {breadcrumb}
                <Divider hidden />
                <div className="row">
                    <div className="responsive-header" style={{ marginBottom: "0.5rem" }}>
                        <div style={{ display: "block" }}>
                            <Header as={"h2"}>
                                <Image src={getImageForConnector(dataset.connection.type!)} size="tiny" inline />
                                {dataset.name}
                            </Header>
                        </div>
                        <div style={{ display: "flex", marginLeft: "auto", maxHeight: `${36 / 14}rem` }}>
                            <Button
                                primary
                                icon
                                labelPosition="left"
                                disabled={dataset?.status !== "connected"}
                                onClick={() => setAugmentDatasetModalOpen(true)}
                            >
                                <Icon name="expand arrows alternate" />
                                Augment Dataset
                            </Button>
                        </div>
                    </div>
                    <DatasetDetailsTable
                        dataset={dataset}
                        refreshFunc={() =>
                            queryClient.invalidateQueries({ queryKey: GET_DATASET_MODELS_QUERY_KEY(datasetID) })
                        }
                    />
                </div>
                {connectionMsg ? <Divider hidden /> : null}
                {connectionMsg}
                <Divider hidden />
            </div>
        );
    }

    const panes = [
        {
            key: "previewer",
            menuItem: "Preview",
            render: () => <DatasetPreviewer dataset={dataset} />,
        },
    ];

    const body = <Tab panes={panes} defaultActiveIndex={0} menu={{ secondary: true, pointing: true }} />;

    let deleteDatasetButton = null;
    if (dataset) {
        deleteDatasetButton = (
            <Modal
                trigger={<Button icon="trash" content="Delete Dataset" size="tiny" basic negative />}
                header="Delete dataset"
                content={
                    <Modal.Content>
                        <span>
                            Are you sure you'd like to delete your dataset {dataset.name}?{" "}
                            <strong>This action cannot be undone.</strong>
                        </span>
                    </Modal.Content>
                }
                size="mini"
                actions={[
                    { key: "cancel", content: "Cancel" },
                    { key: "delete", content: "Delete", negative: true, onClick: mutateDeleteDataset },
                ]}
            />
        );
    }

    return (
        <>
            <div style={{ padding: "20px" }}>
                {header}
                {errorView ? (
                    <>
                        <Divider hidden />
                        <div className="row">{errorView}</div>
                    </>
                ) : null}
                {!errorView && !connectionMsg && schemas ? body : null}
                <Divider hidden />
                {deleteDatasetButton}
            </div>

            {/* Modals: */}
            <AugmentDatasetModal
                open={augmentDatasetModalOpen}
                setOpen={setAugmentDatasetModalOpen}
                datasetUUID={dataset?.uuid}
                datasetName={dataset?.name}
            />
        </>
    );
};

export default DatasetDetailsView;
