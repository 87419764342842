import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { accelerator, baseModel, deployment } from "../../api_generated";
import { useAuth0TokenOptions } from "../../data";
import { DEPLOYMENTS_CONSTANT } from "../../query";
import {
    DeploymentPodLogsWithTimestamp,
    getAccelerators,
    getBaseModels,
    getDeployment,
    getDeploymentLogsWithTimestamp,
    getDeploymentMetrics,
} from "./data";
import { typesafeDeploymentMetrics, unixEpoch } from "./data-utils";

// Queries:
export const GET_DEPLOYMENT_QUERY_KEY = (deploymentUUID: string) => [DEPLOYMENTS_CONSTANT, deploymentUUID];
export const useDeploymentQuery = (deploymentUUID: string, options?: Partial<UseQueryOptions<deployment>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<deployment>({
        queryKey: GET_DEPLOYMENT_QUERY_KEY(deploymentUUID),
        queryFn: () => getDeployment(deploymentUUID, auth0TokenOptions),
        ...options,
    });
};

export const GET_BASE_MODELS_QUERY_KEY = [DEPLOYMENTS_CONSTANT, "base-models"];
export const useBaseModelsQuery = (options?: Partial<UseQueryOptions<baseModel[]>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<baseModel[]>({
        queryKey: GET_BASE_MODELS_QUERY_KEY,
        queryFn: () => getBaseModels(auth0TokenOptions),
        staleTime: 1000 * 60 * 5, // 5 minutes
        ...options,
    });
};

export const GET_ACCELERATORS_QUERY_KEY = [DEPLOYMENTS_CONSTANT, "accelerators"];
export const useAcceleratorsQuery = (options?: Partial<UseQueryOptions<accelerator[]>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<accelerator[]>({
        queryKey: GET_ACCELERATORS_QUERY_KEY,
        queryFn: () => getAccelerators(auth0TokenOptions),
        ...options,
    });
};

export const GET_DEPLOYMENT_LOGS_QUERY_KEY = (deploymentUUID: string) => [DEPLOYMENTS_CONSTANT, deploymentUUID, "logs"];
export const useDeploymentLogsWithTimestampQuery = (
    deploymentUUID: string,
    options?: Partial<UseQueryOptions<DeploymentPodLogsWithTimestamp>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<DeploymentPodLogsWithTimestamp>({
        queryKey: GET_DEPLOYMENT_LOGS_QUERY_KEY(deploymentUUID),
        queryFn: () => getDeploymentLogsWithTimestamp(deploymentUUID, auth0TokenOptions),
        ...options,
    });
};

export const GET_DEPLOYMENT_METRICS_QUERY_KEY = (
    deploymentUUID: string,
    beginTimeWindow?: unixEpoch,
    endTimeWindow?: unixEpoch,
) => [DEPLOYMENTS_CONSTANT, deploymentUUID, "window", beginTimeWindow, endTimeWindow];
export const useDeploymentMetricsQuery = (
    deploymentUUID: string,
    beginTimeWindow?: unixEpoch,
    endTimeWindow?: unixEpoch,
    stepSecs?: number, // ! WARNING: Num secs between data points. Prometheus limit is 11000 data points in one query!
    options?: Partial<UseQueryOptions<typesafeDeploymentMetrics>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<typesafeDeploymentMetrics>({
        queryKey: GET_DEPLOYMENT_METRICS_QUERY_KEY(deploymentUUID, beginTimeWindow, endTimeWindow),
        queryFn: () =>
            getDeploymentMetrics(deploymentUUID, beginTimeWindow, endTimeWindow, stepSecs, auth0TokenOptions),
        ...options,
    });
};
