import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { integration, integrationToken } from "../api_generated";
import { useAuth0TokenOptions } from "../data";
import { MetronomeDashboardType } from "../types/billing";
import { getIntegrationToken, getMetronomeDashboard } from "./data";

// Constants:
export const DASHBOARDS_CONSTANT = "dashboards";
export const INTEGRATIONS_CONSTANT = "integrations";

// Defaults:
export const commonGetMetronomeDashboardQuerySettings = <T>(): Partial<UseQueryOptions<T>> => ({
    // Per Convo with Metronome, dashboard link is valid for 1 hour
    // https://predibase.slack.com/archives/C062V7BKB44/p1702318732279829
    staleTime: 1000 * 60 * 55, // 55 minutes
    gcTime: 1000 * 60 * 55, // 55 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
});

// Queries:
export const GET_METRONOME_DASHBOARD_QUERY_KEY = (type: MetronomeDashboardType) => [DASHBOARDS_CONSTANT, { type }];
export const useMetronomeDashboardQuery = (
    type: MetronomeDashboardType,
    options?: Partial<UseQueryOptions<string>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<string>({
        queryKey: GET_METRONOME_DASHBOARD_QUERY_KEY(type),
        queryFn: () => getMetronomeDashboard(type, auth0TokenOptions),
        ...options,
    });
};

export const USE_INTEGRATION_TOKEN_QUERY_KEY = (integration: integration) => [
    INTEGRATIONS_CONSTANT,
    `${integration}/token`,
];
export const useIntegrationTokenQuery = (
    integration: integration,
    options?: Partial<UseQueryOptions<integrationToken>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<integrationToken>({
        queryKey: USE_INTEGRATION_TOKEN_QUERY_KEY(integration),
        queryFn: () => getIntegrationToken(integration, auth0TokenOptions),
        retry: false,
        ...options,
    });
};
