import { capitalize } from "lodash";
import { role } from "../../api_generated";

export const generateUserRoleDropdownOptions = () => {
    const roleKeys = Object.keys(role).filter((key) => isNaN(Number(key)));
    return roleKeys.map((key) => ({
        text: capitalize(key.toLowerCase()),
        value: key.toLowerCase(),
    }));
};

export const cleanAuthMethods = (authMethods: string[]) => {
    return authMethods.map((method) => {
        switch (method) {
            case "auth0":
                return "Email/Password";
            case "google-oauth2":
                return "Google";
            case "github":
                return "Github";
            default:
                return capitalize(method.toLowerCase());
        }
    });
}
