import { AxiosResponse } from "axios";
import { getTraceId } from "../../api/trace";
import {
    accelerator,
    baseModel,
    createDeploymentRequest,
    deployment,
    deploymentPodLogs,
    updateDeploymentRequest,
} from "../../api_generated";
import { APIResponse, Auth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { createV2APIServer, redirectIfSessionInvalid } from "../../utils/api";
import { getErrorMessage } from "../../utils/errors";
import { typesafeDeploymentMetrics, unixEpoch } from "./data-utils";

// Deployment CRUD operations:
export const createDeployment = async (
    newDeployment: createDeploymentRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = "deployments";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<APIResponse<deployment>>(endpoint, newDeployment)
        .then((res) => {
            return res.data.data; // TODO: Is this double-nesting of "data" normal?
        })
        .catch((error: any) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getDeployment = async (deploymentUUID: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `deployments/${deploymentUUID}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<APIResponse<deployment>>(endpoint)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const updateDeployment = async (
    deploymentUUID: string,
    updateDeployment: updateDeploymentRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `deployments/${deploymentUUID}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .put<APIResponse<deployment>>(endpoint, updateDeployment)
        .then((res) => {
            return res.data.data; // TODO: Is this double-nesting of "data" normal?
        })
        .catch((error: any) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteDeployment = async (deploymentUUID: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `deployments/${deploymentUUID}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return (
        v2APIServer
            .delete(endpoint)
            // TODO: Is undefined the right type here? Should anything even be returned on success?
            .then((res: AxiosResponse<undefined>) => {
                return res.data;
            })
            .catch((error: any) => {
                const errorMsg = getErrorMessage(error) ?? "";
                metrics.captureError("api_error", errorMsg, {
                    method: "DELETE",
                    endpoint,
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
                throw errorMsg;
            })
    );
};

export const getBaseModels = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "base-models";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<APIResponse<baseModel[]>>(endpoint)
        .then((res) => {
            return res.data.data;
        })
        .catch((error: any) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getAccelerators = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "accelerators";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<APIResponse<accelerator[]>>(endpoint)
        .then((res) => {
            return res.data.data;
        })
        .catch((error: any) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export interface DeploymentPodLogsWithTimestamp {
    data: deploymentPodLogs;
    timestamp: number;
}

export const getDeploymentLogsWithTimestamp = async (deploymentUUID: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `deployments/${deploymentUUID}/pod_logs`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<APIResponse<deploymentPodLogs>>(endpoint)
        .then((res) => {
            return {
                data: res.data.data,
                timestamp: Date.now(),
            } as DeploymentPodLogsWithTimestamp;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getDeploymentMetrics = async (
    deploymentUUID: string,
    beginTimeWindow?: unixEpoch,
    endTimeWindow?: unixEpoch,
    stepSecs?: number, // ! WARNING: Num secs between data points. Prometheus limit is 11000 data points in one query!
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `deployments/${deploymentUUID}/metrics`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<typesafeDeploymentMetrics>(endpoint, {
            params: {
                beginTimeWindow,
                endTimeWindow,
                stepSecs,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
