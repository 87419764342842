import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useAuth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { SESSION_STATE, USER_STATE } from "../state/global";
import { redirectIfSessionInvalid } from "./api";
import { getErrorMessage } from "./errors";
import { kratosSdk } from "./kratos";

export const useGetSignOut = () => {
    const [, setSession] = useRecoilState(SESSION_STATE);
    const [, setUser] = useRecoilState(USER_STATE);
    const queryClient = useQueryClient();

    // Route state:
    const navigate = useNavigate();

    // Feature flags:
    const { auth0Enabled } = useAuth0TokenOptions();

    // Auth0 state:
    const { logout } = useAuth0();

    const commonLogout = async () => {
        Sentry.setUser(null);
        // TODO: Unidentify user in June?
        metrics.reset();

        // Invalidate every query in the cache
        // TODO: We should move this to the Login code and only
        // invalidate the queries that are affected when the tenant changes
        queryClient.invalidateQueries();
        queryClient.clear();

        setSession(undefined);
        setUser(undefined);
    };

    const kratosLogout = async () => {
        try {
            // Create a "logout flow" in Ory Identities
            const { data: flow } = await kratosSdk.createBrowserLogoutFlow();
            // Use the received token to "update" the flow and thus perform the logout
            await kratosSdk.updateLogoutFlow({
                token: flow.logout_token,
            });

            commonLogout();

            // Redirect to the login page
            navigate("/auth/signin");
        } catch (error) {
            // The user could not be logged out
            // This typically happens if the token does not match the session,
            // or is otherwise malformed or missing
            let errorMsg: string | null = "";
            if (error instanceof AxiosError) {
                errorMsg = getErrorMessage(error);
            } else if (error instanceof Error) {
                errorMsg = error.message;
            }

            metrics.captureError("api_error", errorMsg ?? "", {
                method: "POST",
                endpoint: "kratos-logout-flow",
            });
            redirectIfSessionInvalid(errorMsg ?? "");
        }
    };

    const auth0Logout = async () => {
        // * NOTE: Logout from Auth0 won't return an error, see:
        // https://community.auth0.com/t/handle-logout-error-in-react-sdk/63235/3
        await logout({
            logoutParams: {
                // TODO: Update this to use the Auth0 client_id in PR moving this to env vars
                // client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                returnTo: `${window.location.origin}/auth/signin`,
            },
        });

        commonLogout();
    };

    if (auth0Enabled) {
        return auth0Logout;
    }

    return kratosLogout;
};
