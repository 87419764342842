import { JSONSchema7, JSONSchema7Type } from "json-schema";
import _ from "lodash";
import { useContext } from "react";
import { computeRestrictedFeature } from "../../../../models/create/FieldsTable/util";
import VerboseOption from "../../../../models/create/VerboseOption";
import { isComputeDisabled } from "../../../../models/create/utils";
import { CurrentUser, UserContext } from "../../../../types/user";
import { FeatureFlagsContext } from "../../../../utils/feature-flags";
import { getComputeSubscriptionStatus } from "../../../../utils/subscription";
import Dropdown from "../../../Dropdown";
import Input from "../../../Input";
import TextArea from "../../../TextArea";
import { dropDownWidths } from "../styling";
import FieldWrapper from "./FieldWrapper";

export interface StringSchema extends JSONSchema7 {
    enumDescriptions?: { [component: string]: { [descLength: string]: string } };
}

export const StringField = (props: {
    path: string;
    title?: string;
    error: boolean;
    value: string;
    description: string | undefined;
    defaultValue?: JSONSchema7Type;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    schema: StringSchema;
    schemaPath: string;
    readOnly?: boolean;
    userContext?: UserContext | CurrentUser;
    hideTitle?: boolean;
}) => {
    const {
        path,
        title,
        error,
        value,
        description,
        defaultValue,
        setConfig,
        setLocalState,
        schema,
        schemaPath,
        userContext,
    } = props;

    const { featureFlags } = useContext(FeatureFlagsContext);

    let el = null;
    let isTextArea = _.get(schema, "parameter_metadata.ui_component_type") === "textarea";
    if (schema.enum && schema.enum.length) {
        let optionsFormatted: any[] = schema.enum.map((option) => {
            const description = _.get(schema, ["enumDescriptions", String(option), "short_description"], undefined);

            const computeTier = _.get(schema, ["enumDescriptions", String(option), "compute_tier"], undefined);

            const computeDisabled =
                (isComputeDisabled(computeTier) || computeRestrictedFeature(String(option))) &&
                getComputeSubscriptionStatus(userContext?.isComputeLimited, featureFlags["Free Trial Testing"]);

            return {
                text: String(option),
                value: option,
                description: description,
                children: (
                    <VerboseOption option={String(option)} description={description} disabled={computeDisabled} />
                ),
                disabled: computeDisabled,
            };
        });

        el = (
            <Dropdown
                path={path}
                title={title}
                error={error}
                options={optionsFormatted}
                value={value}
                description={description}
                defaultValue={defaultValue}
                setConfig={setConfig}
                setLocalState={setLocalState}
                schemaPath={schemaPath}
                size={schema.enumDescriptions ? dropDownWidths.wide : dropDownWidths.normal}
                readOnly={props.readOnly}
                userContext={props.userContext}
            />
        );
    } else {
        if (isTextArea) {
            el = (
                <TextArea
                    path={path}
                    title={title}
                    error={error}
                    value={value}
                    description={description}
                    defaultValue={defaultValue}
                    setConfig={setConfig}
                    setLocalState={setLocalState}
                    schemaPath={schemaPath}
                    readOnly={props.readOnly}
                />
            );
        } else {
            el = (
                <Input
                    type={_.get(schema, "parameter_metadata.ui_component_type", "text")}
                    path={path}
                    title={title}
                    error={error}
                    value={value}
                    description={description}
                    defaultValue={defaultValue}
                    setConfig={setConfig}
                    setLocalState={setLocalState}
                    schemaPath={schemaPath}
                    readOnly={props.readOnly}
                />
            );
        }
    }

    const titlePrefix = _.get(schema, "parameter_metadata.prefix", "");
    return (
        <FieldWrapper
            hideTitle={props.hideTitle}
            title={title}
            titlePrefix={titlePrefix}
            description={description}
            isTextArea={isTextArea}
            path={path}
            schema={schema}
            defaultValue={defaultValue}
        >
            {el}
        </FieldWrapper>
    );
};
