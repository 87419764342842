import _ from "lodash";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Divider, Form, Icon, Popup } from "semantic-ui-react";
import { adapter, deployment } from "../api_generated";
import DeploymentStatusChip from "../deployments/misc/DeploymentStatusChip";
import { track } from "../metrics/june";
import metrics from "../metrics/metrics";
import { USER_STATE } from "../state/global";
import { SEMANTIC_GREY_DISABLED } from "../utils/colors";
import { rawtextSearch } from "../utils/search";
import { useLegacyModelByIDQuery } from "./query";
import { MaxTokensSlider, TemperatureSlider } from "./Sliders";
import {
    AdapterRepoLookup,
    AdapterVersionLookup,
    DeploymentUUIDLookup,
    DropdownItemsArray,
} from "./utils/dropdown-utils";
import { ActionType, useDispatch, usePromptState } from "./utils/reducer";

import "./ControlBar.css";

const AdapterBlogLink = () => {
    const [user] = useRecoilState(USER_STATE);
    const url = "https://predibase.com/blog/lorax-the-open-source-framework-for-serving-100s-of-fine-tuned-llms-in";

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={url}
            target="_blank"
            rel="noopener"
            onClick={() => {
                metrics.capture("docs", {
                    url,
                    clickSource: "prompt-view",
                });
                user &&
                    track(user, "docs", {
                        url,
                        clickSource: "prompt-view",
                    });
            }}
        >
            our LoRAX blogpost
        </a>
    );
};

const AdapterTooltip = () => {
    return (
        <Popup
            className="transition-scale"
            hoverable
            wide={"very"}
            position={"right center"}
            trigger={<Icon name={"question circle"} color={"grey"} />}
            content={
                <span>
                    Any fine-tuned model is called an "adapter" and is available to prompt as an add-on module to its
                    base LLM immediately after training. <b>Currently limited to the latest model in each repo.</b>{" "}
                    Please see <AdapterBlogLink /> for more information.
                </span>
            }
        />
    );
};

const ApplyChatTemplateTooltip = () => {
    return (
        <Popup
            className="transition-scale"
            hoverable
            wide={"very"}
            position={"right center"}
            trigger={<Icon name={"exclamation triangle"} color={"yellow"} />}
            content={
                <span>
                    Warning: If your adapter was trained with “Apply Chat Template”,
                    please be sure to include the
                    <a href="https://docs.predibase.com/user-guide/fine-tuning/chat_templates"> appropriate chat template </a>
                    when prompting this adapter.
                </span>
            }
        />
    );
}

const DeploymentStatusIndicator = (props: { deploymentUUIDLookup: DeploymentUUIDLookup }) => {
    // Parent state:
    const { deploymentUUIDLookup } = props;
    // Reducer state:
    const { selectedDeployment } = usePromptState();

    // Parent state:
    // deploymentUUIDLookup is regenerated every time the prompt UI polls for deployments:
    const upToDateStatus = selectedDeployment?.uuid
        ? (deploymentUUIDLookup[selectedDeployment.uuid] as deployment | undefined)?.status
        : undefined;

    return (
        <div style={{ fontWeight: 400, fontSize: `${12 / 14}rem`, marginBottom: "1rem", marginTop: `-${10 / 14}rem` }}>
            <span style={{ color: SEMANTIC_GREY_DISABLED }}>Status: </span>
            {upToDateStatus ? (
                <DeploymentStatusChip status={upToDateStatus} />
            ) : (
                <span style={{ color: SEMANTIC_GREY_DISABLED }}>Cannot fetch status at this time</span>
            )}
        </div>
    );
};

const ControlBar = (props: {
    deploymentSelectorOptions: DropdownItemsArray;
    deploymentUUIDLookup: DeploymentUUIDLookup;
    setPromptTemplateVisible: (value: React.SetStateAction<boolean>) => void;
    adapterRepoOptions: DropdownItemsArray;
    adapterVersionOptions: DropdownItemsArray;
    selectedAdapterRepo: string;
    setSelectedAdapterRepo: (value: React.SetStateAction<string>) => void;
    adapterRepoLookup: AdapterRepoLookup;
    adapterVersionLookup: AdapterVersionLookup;
    setUserHasTypedAtLeastOnceWithCurrentDeployment: React.Dispatch<React.SetStateAction<boolean>>;
    stopGenerateQuery: () => void;
}) => {
    // Parent state:
    const {
        deploymentSelectorOptions,
        deploymentUUIDLookup,
        setPromptTemplateVisible,
        adapterRepoOptions,
        adapterVersionOptions,
        selectedAdapterRepo,
        setSelectedAdapterRepo,
        adapterRepoLookup,
        adapterVersionLookup,
        setUserHasTypedAtLeastOnceWithCurrentDeployment,
        stopGenerateQuery,
    } = props;

    // Reducer state:
    const dispatch = useDispatch();
    const { selectedDeployment, selectedAdapter, maxNewTokens } = usePromptState();

    // Query state:
    // If the user selects an adapter with the prompt template, update the reducer:
    const { data: legacyModel } = useLegacyModelByIDQuery(selectedAdapter?.legacyModelId?.toString() ?? "", {
        refetchOnWindowFocus: false,
        enabled: Boolean(selectedAdapter?.legacyModelId?.toString()),
    });
    useEffect(() => {
        if (legacyModel && _.has(legacyModel.config, "prompt.template")) {
            const promptTemplate = _.get(legacyModel.config, "prompt.template") as string;
            if (promptTemplate !== "") {
                dispatch({
                    type: ActionType.UPDATE,
                    promptTemplate,
                });
                setPromptTemplateVisible(true);
            }
        }
    }, [legacyModel, dispatch, setPromptTemplateVisible]);

    return (
        <>
            <Form style={{ width: "100%", marginBottom: "1.5rem" }}>
                <Form.Select
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    name="selectedDeployment"
                    label={"LLM Deployment"}
                    options={deploymentSelectorOptions}
                    // ! NOTE: Leaving value as undefined may not properly clear selection when user clicks the X. See:
                    // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625#issuecomment-654199235
                    value={selectedDeployment?.uuid || ""}
                    text={deploymentSelectorOptions.find((option) => option.key === selectedDeployment?.uuid)?.rawtext}
                    placeholder="None"
                    onChange={(_, { value }) => {
                        // Update the deployment selection, clear all other state in the reducer:
                        const newDeployment = deploymentUUIDLookup[value as deployment["uuid"]] as
                            | deployment
                            | undefined;

                        if (newDeployment) {
                            dispatch({
                                type: ActionType.UPDATE,
                                selectedDeployment: newDeployment,
                                selectedAdapter: null,
                                maxNewTokens: Math.min(maxNewTokens, newDeployment?.model.maxTotalTokens),
                            });
                        } else {
                            dispatch({
                                type: ActionType.UPDATE,
                                selectedDeployment: null,
                                selectedAdapter: null,
                            });
                        }

                        // Update UI state:
                        setPromptTemplateVisible(false);
                        setSelectedAdapterRepo("");
                        setUserHasTypedAtLeastOnceWithCurrentDeployment(false);

                        // Cancel any running query and clear the current response data:
                        stopGenerateQuery();
                    }}
                    selection
                    fluid
                    search={rawtextSearch}
                />
                {selectedDeployment && <DeploymentStatusIndicator deploymentUUIDLookup={deploymentUUIDLookup} />}

                <Popup
                    className="transition-scale"
                    content={"No adapters available for this deployment"}
                    position={"right center"}
                    wide={true}
                    trigger={
                        <div>
                            <Form.Select
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                name="selectedAdapterRepo"
                                style={{ marginBottom: "1rem" }}
                                label={
                                    <label>
                                        Fine-tuned Adapter <AdapterTooltip />
                                    </label>
                                }
                                options={adapterRepoOptions}
                                // ! NOTE: Leaving value as undefined may not propery clear selection when user clicks the X. See:
                                // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625#issuecomment-654199235
                                value={selectedAdapterRepo}
                                placeholder="None"
                                onChange={(_, { value }) => {
                                    // Update the adapter repo selection:
                                    setSelectedAdapterRepo(value as string);

                                    // Try to auto-select the latest version of the adapter:
                                    const possibleAdapterVersionOptions = adapterRepoLookup[value as string] as
                                        | adapter[]
                                        | undefined;
                                    if (possibleAdapterVersionOptions) {
                                        let latestVersionAdapter = possibleAdapterVersionOptions[0];
                                        possibleAdapterVersionOptions.forEach((adapter) => {
                                            if (adapter.versionTag > latestVersionAdapter.versionTag) {
                                                latestVersionAdapter = adapter;
                                            }
                                        });
                                        dispatch({
                                            type: ActionType.UPDATE,
                                            selectedAdapter: latestVersionAdapter,
                                        });

                                        // If it's a legacy adapter (i.e. has a prompt template), clear out the current prompt:
                                        if (latestVersionAdapter.legacyModelId) {
                                            dispatch({
                                                type: ActionType.UPDATE,
                                                prompt: "",
                                                promptTemplate: null,
                                                promptTemplateVariables: {},
                                            });
                                        }
                                    }
                                    // Otherwise, clear the adapter state in the reducer (anticipating the user's choice):
                                    else {
                                        dispatch({
                                            type: ActionType.UPDATE,
                                            selectedAdapter: null,
                                        });
                                    }

                                    // Cancel any running query and clear the current response data:
                                    stopGenerateQuery();
                                }}
                                selection
                                fluid
                                search={rawtextSearch}
                                clearable
                                disabled={adapterRepoOptions.length === 0}
                            />
                        </div>
                    }
                    disabled={adapterRepoOptions.length > 0}
                />

                <Popup
                    className="transition-scale"
                    content={"No adapter versions available for this repo"}
                    position={"right center"}
                    wide={true}
                    trigger={
                        <div>
                            <Form.Select
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                name="selectedAdapterVersion"
                                label={<label>Adapter Version <ApplyChatTemplateTooltip /> </label>}
                                options={adapterVersionOptions}
                                // ! NOTE: Leaving value as undefined may not propery clear selection when user clicks the X. See:
                                // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625#issuecomment-654199235
                                value={selectedAdapter?.versionTag.toString() || ""}
                                placeholder="None"
                                onChange={(_, { value }) => {
                                    // Update the adapter selection, clear the prompt state:
                                    const selectedAdapter = adapterVersionLookup[value as string] as
                                        | adapter
                                        | undefined;
                                    dispatch({
                                        type: ActionType.UPDATE,
                                        selectedAdapter,
                                    });

                                    // If it's a legacy adapter (i.e. has a prompt template), clear out the current prompt:
                                    if (selectedAdapter?.legacyModelId) {
                                        dispatch({
                                            type: ActionType.UPDATE,
                                            prompt: "",
                                            promptTemplate: null,
                                            promptTemplateVariables: {},
                                        });
                                    }

                                    // Cancel any running query and clear the current response data:
                                    stopGenerateQuery();
                                }}
                                selection
                                fluid
                                search={rawtextSearch}
                                clearable
                                disabled={adapterVersionOptions.length === 0}
                            />
                        </div>
                    }
                    // TODO: Log an error because this should technically never happen (every repo must have at LEAST
                    // one version for the UI to even work!)
                    disabled={adapterVersionOptions.length > 0}
                />
            </Form>
            <TemperatureSlider />
            <Divider hidden />
            <MaxTokensSlider />
        </>
    );
};

export default ControlBar;
