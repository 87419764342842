/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum acceleratorId {
    A100_80GB_025 = 'a100_80gb_025',
    A100_80GB_050 = 'a100_80gb_050',
    A100_80GB_100 = 'a100_80gb_100',
    A100_80GB_200 = 'a100_80gb_200',
    A100_80GB_400 = 'a100_80gb_400',
    A10_24GB_100 = 'a10_24gb_100',
    A10_24GB_400 = 'a10_24gb_400',
    A10_24GB_800 = 'a10_24gb_800',
    L40S_48GB_100 = 'l40s_48gb_100',
    L4_24GB_100 = 'l4_24gb_100',
    H100_80GB_PCIE_100 = 'h100_80gb_pcie_100',
    H100_80GB_PCIE_200 = 'h100_80gb_pcie_200',
    H100_80GB_PCIE_400 = 'h100_80gb_pcie_400',
}
