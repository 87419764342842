import { useContext, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Divider, Dropdown, Header, Icon, Input, Table } from "semantic-ui-react";
import { deployment, deploymentType, tier } from "../../../api_generated";
import metrics from "../../../metrics/metrics";
import { USER_STATE } from "../../../state/global";
import { getDocsHome } from "../../../utils/api";
import { SEMANTIC_GREY } from "../../../utils/colors";
import { FeatureFlagsContext, getPosthogFeatureFlag, POSTHOG_FEATURE_FLAG } from "../../../utils/feature-flags";
import { isKratosUserContext } from "../../../utils/kratos";
import { filterListOnSelectedAttributeWithSearchText } from "../../../utils/search";
import { centsToDollars } from "../../../utils/strings";
import DeploymentStatusChip from "../../misc/DeploymentStatusChip";
import GuaranteedDeploymentChip from "../../misc/GuaranteedDeploymentChip";
import { isDeploymentPromptable } from "../../misc/utils";
import CreateUpdateDeploymentModal from "../modals/CreateUpdateDeploymentModal";
import DeleteDeploymentModal from "../modals/DeleteDeploymentModal";
import PromptInstructionsModal from "../modals/PromptInstructionsModal";

const DedicatedDeploymentsLink = (props: { text: string }) => {
    const url = getDocsHome() + "/user-guide/inference/dedicated_deployments";

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={url}
            target="_blank"
            rel="noopener"
            onClick={() => {
                metrics.capture("docs", {
                    url,
                });
            }}
        >
            {props.text}
        </a>
    );
};

const DedicatedDeploymentsTable = (props: { deployments?: deployment[] }) => {
    // Meta state:
    const navigate = useNavigate();
    const [userContext] = useRecoilState(USER_STATE);

    // Parent state:
    const { deployments } = props;

    // Local state:
    const [searchState, setSearchState] = useState<string>("");
    const [selectedDeployment, setSelectedDeployment] = useState<deployment | null>(null);
    const [promptInstructionsModalOpen, setPromptInstructionsModalOpen] = useState(false);
    const [updateDeploymentModalOpen, setUpdateDeploymentModalOpen] = useState(false);
    const [deleteDeploymentModalOpen, setDeleteDeploymentModalOpen] = useState(false);

    // Context state:
    const { posthogFeatureFlags } = useContext(FeatureFlagsContext);
    const showReplicasCount = getPosthogFeatureFlag(POSTHOG_FEATURE_FLAG.ShowReplicasCount, posthogFeatureFlags);

    // Computed state:
    const filteredDeployments = useMemo(() => {
        if (deployments) {
            return filterListOnSelectedAttributeWithSearchText<deployment>(deployments!, "name", searchState);
        }
        return null;
    }, [deployments, searchState]);

    // Derived user state:
    let userTier: tier | undefined;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        userTier = isKratosContext ? userContext.tenant.subscription.tier : userContext?.tenant.tier;
    }
    const userIsInEnterpriseTier = userTier && [tier.ENTERPRISE_SAAS, tier.ENTERPRISE_VPC].includes(userTier);

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <div style={{ display: "flex" }}>
                                <span style={{ marginTop: "0.5rem", marginRight: "1rem" }}>Name </span>
                                <Input
                                    autoFocus
                                    size={"small"}
                                    icon={"search"}
                                    onChange={(event, data) => {
                                        setSearchState(data.value);
                                    }}
                                />
                            </div>
                        </Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Cooldown Time (s)</Table.HeaderCell>
                        {/* <Table.HeaderCell>Base Model</Table.HeaderCell> */}
                        <Table.HeaderCell>Context Window</Table.HeaderCell>
                        {/* <Table.HeaderCell>Parameters</Table.HeaderCell> */}
                        {!userIsInEnterpriseTier && <Table.HeaderCell>Price (per hour)</Table.HeaderCell>}
                        {showReplicasCount && <Table.HeaderCell>Replicas</Table.HeaderCell>}
                        {showReplicasCount && <Table.HeaderCell>Current Replicas</Table.HeaderCell>}
                        {/* // Empty header for kebab dropdown: */}
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredDeployments && filteredDeployments.length > 0 ? (
                        filteredDeployments.map((deployment) => (
                            <Table.Row key={deployment.name}>
                                <Table.Cell>
                                    <Link to={`/deployments/${deployment.uuid}`} style={{ paddingRight: 10 }}>
                                        {deployment.name}
                                    </Link>
                                    {deployment?.usesGuaranteedCapacity && <GuaranteedDeploymentChip />}
                                </Table.Cell>
                                <Table.Cell>
                                    <DeploymentStatusChip status={deployment.status} />
                                </Table.Cell>
                                <Table.Cell>{`${deployment?.config?.cooldownTime}`}</Table.Cell>
                                {/* <Table.Cell>{deployment.model.canonicalName}</Table.Cell> */}
                                <Table.Cell>{deployment.model.maxTotalTokens}</Table.Cell>
                                {/* <Table.Cell>{deployment.model.numParams}</Table.Cell> */}
                                {!userIsInEnterpriseTier && (
                                    <Table.Cell>
                                        {/* TODO: When cost is available for all templates, remove the guard: */}
                                        {deployment.cost.centsPerHour > 0
                                            ? centsToDollars(deployment.cost.centsPerHour)
                                            : "--"}
                                    </Table.Cell>
                                )}
                                {showReplicasCount && (
                                    <Table.Cell>
                                        {deployment?.config?.minReplicas} → {deployment?.config?.maxReplicas}
                                    </Table.Cell>
                                )}
                                {showReplicasCount && <Table.Cell>{deployment.currentReplicas}</Table.Cell>}
                                <Table.Cell collapsing>
                                    <Dropdown
                                        closeOnBlur
                                        direction="left"
                                        icon="ellipsis vertical"
                                        className="icon mini basic"
                                        style={{ color: SEMANTIC_GREY }}
                                        closeOnChange
                                    >
                                        <Dropdown.Menu style={{ borderStyle: "solid" }}>
                                            <Dropdown.Item
                                                onClick={() =>
                                                    navigate(
                                                        `/prompt?model=${encodeURIComponent(deployment?.uuid ?? "")}`,
                                                    )
                                                }
                                            >
                                                Prompt via UI
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setSelectedDeployment(deployment);
                                                    setPromptInstructionsModalOpen(true);
                                                }}
                                                disabled={!isDeploymentPromptable(deployment)}
                                            >
                                                Prompt via REST API/SDK/CLI
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setSelectedDeployment(deployment);
                                                    setUpdateDeploymentModalOpen(true);
                                                }}
                                                disabled={!deployment.updatable}
                                            >
                                                Update deployment
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setSelectedDeployment(deployment);
                                                    setDeleteDeploymentModalOpen(true);
                                                }}
                                            >
                                                Delete deployment
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    ) : (
                        <Table.Row>
                            <Table.Cell colSpan={5} textAlign={"center"} verticalAlign={"middle"}>
                                <Divider hidden />
                                <Header as="h2" style={{ marginBottom: "0.5rem", fontSize: `${15 / 14}rem` }}>
                                    You don't have any private serverless deployments yet.
                                </Header>
                                <div style={{ marginLeft: "10rem", marginRight: "10rem" }}>
                                    {userTier === tier.ENTERPRISE_VPC ? (
                                        <span>
                                            VPC customers need to{" "}
                                            <DedicatedDeploymentsLink text="deploy a base model" /> to begin prompting.
                                        </span>
                                    ) : (
                                        <span>
                                            If you're looking to prompt a fine-tuned adapter, we recommend first
                                            prompting with LoRAX and our serverless LLMs, which does not require
                                            deploying. When you're ready for production,{" "}
                                            <DedicatedDeploymentsLink text="Spin up a private serverless deployment" />{" "}
                                            and use LoRAX to prompt your adapter.
                                        </span>
                                    )}
                                </div>

                                <Divider hidden />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>

            {/* Modals: */}
            {selectedDeployment && (
                <PromptInstructionsModal
                    deployment={selectedDeployment}
                    open={promptInstructionsModalOpen}
                    setOpen={setPromptInstructionsModalOpen}
                />
            )}
            {selectedDeployment && (
                <CreateUpdateDeploymentModal
                    open={updateDeploymentModalOpen}
                    setOpen={setUpdateDeploymentModalOpen}
                    deployments={deployments}
                    deployment={selectedDeployment}
                />
            )}
            {selectedDeployment && (
                <DeleteDeploymentModal
                    deployment={selectedDeployment}
                    open={deleteDeploymentModalOpen}
                    setOpen={setDeleteDeploymentModalOpen}
                />
            )}
        </>
    );
};

const DedicatedDeployments = (props: { deployments: deployment[] | null | undefined }) => {
    // Local state:
    const [createDeploymentModalOpen, setCreateDeploymentModalOpen] = useState(false);

    // Derived state:
    const dedicatedDeployments = props.deployments?.filter(
        (sharedDeployment) => sharedDeployment.type === deploymentType.DEDICATED,
    );

    return (
        <>
            <div className="responsive-header">
                <div style={{ display: "block" }}>
                    <Header as="h3" style={{ marginBottom: 0 }}>
                        Private Serverless Deployments
                    </Header>
                    <p>Production-grade inference. Deploy a single base model and serve 100s of fine-tuned adapters.</p>
                </div>
                <Button primary icon labelPosition="left" onClick={() => setCreateDeploymentModalOpen(true)}>
                    <Icon name="plus" />
                    New Private Serverless Deployment
                </Button>
            </div>
            <DedicatedDeploymentsTable deployments={dedicatedDeployments} />

            {/* Modals: */}
            <CreateUpdateDeploymentModal
                open={createDeploymentModalOpen}
                setOpen={setCreateDeploymentModalOpen}
                deployments={dedicatedDeployments}
            />
        </>
    );
};

export default DedicatedDeployments;
