import { useSearchParams } from "react-router-dom";
import { Header, Message } from "semantic-ui-react";
import { PREDIBASE_LIGHT_GRAY } from "../../utils/colors";
import AuthenticationOptions, { AuthenticationModes } from "./AuthenticationOptions";
import { useValidateInviteTokenQuery } from "./query";

const InviteView = () => {
    // Router/URL state:
    const [searchParams] = useSearchParams();
    const inviteToken = searchParams.get("inviteToken") || undefined;

    // Query state:
    const { data: validatedToken, error: tokenIsValidError } = useValidateInviteTokenQuery(inviteToken);

    return tokenIsValidError ? (
        <div style={{ padding: "20px" }}>
            {/* TODO: Better message? */}
            <Header as={"h2"}>Invalid invite token</Header>
            <Message negative>
                Please check the invite link and try again, or contact the person who invited you.
            </Message>
        </div>
    ) : (
        <div
            style={{
                minHeight: "100vh",
                background: PREDIBASE_LIGHT_GRAY,
                display: "flex",
                justifyContent: "center",
                padding: "20px",
                flexDirection: "column"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: "2rem"
                }}>
                <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
                <Header as="h2" textAlign="center">
                    Join your team, <strong>{validatedToken?.tenantName}</strong>, on Predibase!
                </Header>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    maxWidth: "400px",
                    alignSelf: "center",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <AuthenticationOptions
                    inviteToken={inviteToken}
                    tenantName={validatedToken?.tenantName}
                    mode={AuthenticationModes.SignUp}
                />
            </div>
        </div>
    );
};

export default InviteView;