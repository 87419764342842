import { CSSProperties, SyntheticEvent } from "react";
import { DropdownItemProps, DropdownProps, Form } from "semantic-ui-react";

import { handleLocalState } from "../models/create/forms/utils";
import { CurrentUser, UserContext } from "../types/user";
import { dropDownWidths } from "./forms/json-schema-form/styling";

// TODO(ksbrar): This is a hacky method aimed at number values and specifically for the 08/02/23 release. Clean this up after!
const matchValueToOption = (value: any, options: any[], defaultValue: any) => {
    if (Number.isInteger(value)) {
        return value;
    }

    return value ? (Array.isArray(value) ? value.map((v) => String(v)) : value) : defaultValue;
};

const Dropdown = (props: {
    path: string;
    title?: string;
    error: boolean;
    multiple?: boolean;
    fluid?: boolean;
    options: any[];
    value: number | string | string[];
    description?: string;
    defaultValue: any;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    schemaPath: string;
    style?: CSSProperties;
    size?: dropDownWidths;
    readOnly?: boolean;
    userContext?: UserContext | CurrentUser;
}) => (
    <Form.Select
        search
        fluid={props.fluid}
        multiple={props.multiple}
        selection={props.multiple}
        disabled={props.readOnly}
        key={props.path + "_dropdown"}
        name={props.title}
        error={props.error}
        options={props.options}
        value={matchValueToOption(props.value, props.options, props.defaultValue)}
        style={{
            opacity: 1.0,
            width: "inherit",
            minWidth: props?.size ? `${props.size}rem` : "100%",
            marginRight: "8px",
            ...props.style,
        }}
        placeholder={props.defaultValue ? String(props.defaultValue) : props.title}
        renderLabel={
            props.multiple ? (option: DropdownItemProps) => ({ color: "blue", content: option.text }) : undefined
        }
        onChange={(event: SyntheticEvent, data: DropdownProps) => {
            // @ts-expect-error
            handleLocalState(props.path, data.value, props.setLocalState, props.setConfig);
        }}
    />
);

export default Dropdown;
