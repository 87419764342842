import { Tab } from "semantic-ui-react";
import { deploymentEventUpdate } from "../../../../api_generated";
import { SEMANTIC_WHITE } from "../../../../utils/colors";
import { TERMINAL_BLACK_BACKGROUND } from "../../../misc/utils";

const DeploymentEventsTab = (props: { events?: deploymentEventUpdate[] }) => {
    const { events } = props;
    return (
        <Tab.Pane
            key={"deploymentEvents"}
            as={"div"}
            style={{ minHeight: "40rem", background: TERMINAL_BLACK_BACKGROUND }}
        >
            {Array.isArray(events) && events.length > 0 && (
                <ul style={{ listStyleType: "none", padding: 0 }}>
                    {events.map((event, index) => {
                        return (
                            <li key={index} style={{ padding: "10px 20px", color: SEMANTIC_WHITE }}>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "1rem" }}>
                                        {new Date(event.timeStamp).toLocaleString()}
                                    </span>
                                    <span style={{ fontWeight: "bold" }}>{event.eventType}</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            )}
        </Tab.Pane>
    );
};

export default DeploymentEventsTab;
