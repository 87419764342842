import { Slider } from "antd";
import { SEMANTIC_GREY } from "../utils/colors";
import { ActionType, useDispatch, usePromptState } from "./utils/reducer";

// TODO: Let's get rid of antd if we can!
export const TemperatureSlider = () => {
    const dispatch = useDispatch();
    const { temperature } = usePromptState();

    return (
        <>
            <label style={{ display: "flex", justifyContent: "space-between" }}>
                <b>Temperature</b> <span>{temperature}</span>
            </label>
            <div className="icon-wrapper">
                <Slider
                    value={temperature}
                    onChange={(val) => dispatch({ type: ActionType.UPDATE, temperature: val })}
                    styles={{
                        rail: { height: `{6/14}rem` },
                        track: { backgroundColor: "black", height: `{6/14}rem` },
                        handle: { borderColor: SEMANTIC_GREY },
                    }}
                    step={0.1}
                    min={0.1}
                    max={2}
                />
            </div>
        </>
    );
};

export const MaxTokensSlider = () => {
    const dispatch = useDispatch();
    const { selectedDeployment, maxNewTokens } = usePromptState();

    return (
        <>
            <label style={{ display: "flex", justifyContent: "space-between" }}>
                <b>Max New Tokens</b> <span>{maxNewTokens}</span>
            </label>
            <Slider
                value={maxNewTokens}
                onChange={(val) => dispatch({ type: ActionType.UPDATE, maxNewTokens: val })}
                styles={{
                    rail: { height: `{6/14}rem` },
                    track: { backgroundColor: "black", height: `{6/14}rem` },
                    handle: { borderColor: SEMANTIC_GREY },
                }}
                step={1}
                min={1}
                max={selectedDeployment?.model.maxTotalTokens}
            />
        </>
    );
};
