import objectHash from "object-hash";
import { predifineMetricsPayload } from "../../../../api_generated";

// Metrics are graphed against the checkpoint number:
export type LineAxisType = predifineMetricsPayload["data"]["checkpoint_number"];
export const LINE_AXIS_NAME = "checkpoint_number";

// Not everything in the payload is graphed:
// TODO: Streamline and restructure payload data even more:
export type MetricsPayloadData = predifineMetricsPayload["data"];
export type MetricsPayloadDataKey = keyof Omit<MetricsPayloadData, "checkpoint_number">;
export const CHART_METRIC_NAMES: MetricsPayloadDataKey[] = ["train_metrics_loss", "validation_metrics_loss"];
// TODO: Establish enforced naming standard so that we can pick and splice names automatically instead of maintaining
// another data structure for user-friendly names or labels:
export const metricNameMap: { [key: string]: string } = {
    train_metrics_loss: "Train Loss",
    validation_metrics_loss: "Validation Loss",
};

// TODO: Hack for R12 (See Slack thread):
// TODO: Actually pretty useful function to keep around in general:
export const deduplicateArray = <T>(arr: NonNullable<T>[]): T[] => {
    const tracker: { [hash: string]: true } = {};
    return arr.filter((val) => {
        const hash = objectHash(val);
        if (hash in tracker) {
            return false;
        }
        tracker[hash] = true;
        return true;
    });
};
