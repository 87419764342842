import { useRecoilState } from "recoil";
import { Tab } from "semantic-ui-react";
import { DATA_TAB_STATE } from "../state/data";
import ConnectDataModal from "./connect/ConnectDataModal";
import ListConnectionsView from "./connections/ListConnectionsView";
import ListDatasetsView from "./datasets/ListDatasetsView";

function DataView() {
    const [tabState, setTabState] = useRecoilState(DATA_TAB_STATE);

    const panes = [
        {
            key: "connections",
            menuItem: "Connections",
            render: () => <ListConnectionsView />,
        },
        {
            key: "datasets",
            menuItem: "Datasets",
            render: () => <ListDatasetsView />,
        },
    ];

    return (
        <div style={{ padding: "20px" }}>
            <ConnectDataModal onSubmit={() => setTabState(1)} />
            <Tab
                panes={panes}
                menu={{ secondary: true, pointing: true }}
                activeIndex={tabState}
                onTabChange={(e, { activeIndex }) => setTabState(activeIndex as number)}
                style={{ marginTop: "20px" }}
            />
        </div>
    );
}

export default DataView;
