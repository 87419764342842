import _ from "lodash";
import { CSSProperties } from "react";

import Dropdown from "../../../Dropdown";
import Markdown from "../../../Markdown";
import { FieldTitle } from "../fields";
import { dropDownWidths } from "../styling";

export const DualInput = (props: {
    path: string;
    title?: string;
    error: boolean;
    description?: string;
    value?: string[];
    defaultValue: any;
    config: any;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    schemaPath: string;
    style?: CSSProperties;
    readOnly?: boolean;
}) => {
    const { config, description, error, schemaPath, setLocalState, setConfig, title, path, value } = props;
    const otherEntityValues = _.get(config, `combiner.entity_${path.endsWith("1") ? "2" : "1"}`, []);
    const inputFeatures = props.config.input_features
        .map((feature: any) => ({
            key: feature.name,
            text: feature.name,
            value: feature.name,
        }))
        .filter((feature: any) => !otherEntityValues.includes(feature.value));

    return (
        <div style={{ marginBottom: `${24 / 14}rem` }} key={`${path}_array`}>
            <FieldTitle title={title} />
            {description && description.length && <Markdown children={description} secondary={true} />}
            <Dropdown
                path={path}
                error={error}
                fluid={true}
                multiple={true}
                options={inputFeatures}
                value={value ? value : []}
                defaultValue={"Feature Name(s)"}
                setConfig={setConfig}
                setLocalState={setLocalState}
                schemaPath={schemaPath}
                size={dropDownWidths.wide}
                readOnly={props.readOnly}
            />
        </div>
    );
};
