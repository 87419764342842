import { acceleratorId, deployment, deploymentStatus } from "../../api_generated";

// TODO: Remove null when we fix gateway middleware!
export const isDeploymentPromptable = (deployment?: deployment | null): boolean => {
    switch (deployment?.status) {
        case deploymentStatus.READY:
        case deploymentStatus.STANDBY:
        case deploymentStatus.INITIALIZING:
        case deploymentStatus.UPDATING:
            return true;
        default:
            return false;
    }
};

export const formatAcceleratorID = (acceleratorId?: acceleratorId | null) => {
    if (acceleratorId === null || acceleratorId === undefined) {
        return "—";
    }

    const gpuID = acceleratorId!;
    let gpuType = gpuID.split("_")[0].toUpperCase();
    if (gpuType === "A10") {
        gpuType = "A10G";
    }
    const gpuFrac = Number(gpuID.split("_").at(-1)) / 100;

    return `${gpuFrac} x ${gpuType}`;
};

export const TERMINAL_BLACK_BACKGROUND = "rgb(35, 35, 35)";
